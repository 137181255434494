/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import BlockText from "../components/BlockText";
import BlockQuote from "../components/BlockQuote";
import BlockPrayer from "../components/BlockPrayer";
import BlockBiog from "../components/BlockBiog";
import SEO from "../components/Seo";
import ReactPlayer from "react-player/lazy";

// markup
const AboutPage = ({ data }) => {
  const bannerImage = getImage(data.graphCmsPagesAbout.banner.image);
  const blocks = data.graphCmsPagesAbout.blocks;
  const patrons = data.graphCmsPagesAbout.patrons;
  console.log("blocks", blocks);
  return (
    <Layout>
      <SEO title="About" />
      <GatsbyImage image={bannerImage} alt="" />

      {blocks.map((block, index) => {
        switch (block.remoteTypeName) {
          case "BlockText":
            return <BlockText data={block} pageTitle key={`block${index}`} />;
          case "BlockQuote":
            return <BlockQuote data={block} key={`block${index}`} />;
          case "BlockPrayer":
            return <BlockPrayer data={block} key={`block${index}`} />;
          default:
            return <></>;
        }
      })}
      <section
        sx={{
          "& .biogContainer > div:nth-child(even)": {
            flexDirection: "row-reverse",
          },
          h2: {
            textAlign: "center",
            fontSize: 5,
          },
          "& .videoPlayer": {
            mx: [2, 3, 4],
            mb: 4,
            position: "relative",
            paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
            "& > div": {
              position: "absolute",
              top: "0",
              left: "0",
            },
          },
        }}
      >
        <h2>{data.graphCmsPagesAbout.patronsTitle}</h2>
        <Box className="biogContainer">
          {patrons.map((patron, index) => {
            return <BlockBiog data={patron} key={`patron-${index}`} />;
          })}
        </Box>
        {data.graphCmsPagesAbout.videoUrl && (
          <Box className="videoPlayer">
            <ReactPlayer
              url={data.graphCmsPagesAbout.videoUrl}
              width="100%"
              height="100%"
            />
          </Box>
        )}
      </section>
    </Layout>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutPageQuery {
    graphCmsPagesAbout(
      id: { eq: "PagesAbout:ckrv50e94h0qf0b59qg0kxsr4:PUBLISHED" }
    ) {
      id
      videoUrl
      blocks {
        ... on GraphCMS_BlockQuote {
          id
          remoteTypeName
          author
          quote
          image {
            alt
            fileName
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1
              width: 900
            )
          }
        }
        ... on GraphCMS_BlockText {
          id
          remoteTypeName
          text {
            html
            raw
          }
          columns
          title
        }
      }
      patronsTitle
      patrons {
        id
        image {
          alt
          gatsbyImageData(aspectRatio: 1, layout: CONSTRAINED)
          remoteTypeName
        }
        name
        role
        text {
          html
          raw
        }
      }
      title
      banner {
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: DOMINANT_COLOR
            aspectRatio: 1.97
          )
          alt
        }
      }
    }
  }
`;
