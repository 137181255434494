/** @jsx jsx */
import { Box, jsx } from "theme-ui";
//import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BlockBiog = ({ data }) => {
  const image = getImage(data.image);
  return (
    <Box sx={styles} className="biog">
      <Box className="biogImage">
        <GatsbyImage image={image} alt={data.name} aspectRatio={1.5} />
      </Box>

      <Box className="biogText">
        <header>
          <h3>{data.name}</h3>
          {data.role && <h4>{data.role}</h4>}
        </header>

        <div dangerouslySetInnerHTML={{ __html: data.text.html }} />
      </Box>
    </Box>
  );
};

export default BlockBiog;

const styles = {
  display: ["block", "flex"],
  my: 4,
  mx: 4,
  "& .biogText": {
    width: ["100%", "52%"],
    px: 3,
    header: {
      mt: [3, 0],
      display: "flex",
      justifyContent: "center",
      h3: {
        fontSize: 3,
        my: 0,
        bg: "house2",
        color: "white",
        px: 2,
        pt: "2px",
      },
      h4: {
        fontSize: 3,
        my: 0,
        px: 2,
        pt: "1px",
        color: "house2",
        border: (theme) => `2px solid ${theme.colors.house2}`,
        bg: "white",
        fontWeight: "normal",
      },
    },
    div: {
      fontSize: 3,
      mx: 3,
      textAlign: "center",
      mt: 4,
    },
  },
  "& .biogImage": {
    width: ["100%", "48%"],
    "& .gatsby-image-wrapper": {
      width: "100%",
    },
  },
};
